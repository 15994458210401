import * as actionsTypes from './types';

export function createOrganization(payload) {
  return {
    type: actionsTypes.CREATE_ORGANIZATION,
    payload
  };
}
export function createOrganizationSuccess(payload) {
  return {
    type: actionsTypes.CREATE_ORGANIZATION_SUCCESS,
    payload
  };
}
export function createOrganizationFail(payload) {
  return {
    type: actionsTypes.CREATE_ORGANIZATION_FAIL,
    payload
  };
}

export function getListOrganizations(payload) {
  return {
    type: actionsTypes.GET_LIST_ORGANIZATIONS,
    payload
  };
}
export function getListOrganizationsSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_ORGANIZATIONS_SUCCESS,
    payload
  };
}
export function getListOrganizationsFail(payload) {
  return {
    type: actionsTypes.GET_LIST_ORGANIZATIONS_FAIL,
    payload
  };
}

export function getInfoOrganization(payload) {
  return {
    type: actionsTypes.GET_INFO_ORGANIZATION,
    payload
  };
}
export function getInfoOrganizationSuccess(payload) {
  return {
    type: actionsTypes.GET_INFO_ORGANIZATION_SUCCESS,
    payload
  };
}
export function getInfoOrganizationFail(payload) {
  return {
    type: actionsTypes.GET_INFO_ORGANIZATION_FAIL,
    payload
  };
}

export function editInfoOrganization(payload) {
  return {
    type: actionsTypes.EDIT_INFO_ORGANIZATION,
    payload
  };
}
export function editInfoOrganizationSuccess(payload) {
  return {
    type: actionsTypes.EDIT_INFO_ORGANIZATION_SUCCESS,
    payload
  };
}
export function editInfoOrganizationFail(payload) {
  return {
    type: actionsTypes.EDIT_INFO_ORGANIZATION_FAIL,
    payload
  };
}

export function deleteInfoOrganization(payload) {
  return {
    type: actionsTypes.DELETE_INFO_ORGANIZATION,
    payload
  };
}
export function deleteInfoOrganizationSuccess(payload) {
  return {
    type: actionsTypes.DELETE_INFO_ORGANIZATION_SUCCESS,
    payload
  };
}
export function deleteInfoOrganizationFail(payload) {
  return {
    type: actionsTypes.DELETE_INFO_ORGANIZATION_FAIL,
    payload
  };
}

export function getStaticOrganization(payload) {
  return {
    type: actionsTypes.GET_STATIC_ORGANIZATION,
    payload
  };
}
export function getStaticOrganizationSuccess(payload) {
  return {
    type: actionsTypes.GET_STATIC_ORGANIZATION_SUCCESS,
    payload
  };
}
export function getStaticOrganizationFail(payload) {
  return {
    type: actionsTypes.GET_STATIC_ORGANIZATION_FAIL,
    payload
  };
}

export function setSelectOrganization(payload) {
  return {
    type: actionsTypes.SET_SELECT_ORGANIZATION,
    payload
  };
}

export function getListOrganizationReports(payload) {
  return {
    type: actionsTypes.GET_LIST_ORGANIZATION_REPORTS,
    payload
  };
}
export function getListOrganizationReportsSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_ORGANIZATION_REPORTS_SUCCESS,
    payload
  };
}
export function getListOrganizationReportsFail(payload) {
  return {
    type: actionsTypes.GET_LIST_ORGANIZATION_REPORTS_FAIL,
    payload
  };
}

export function getCreateOrganizationReport(payload) {
  return {
    type: actionsTypes.GET_CREATE_ORGANIZATION_REPORT,
    payload
  };
}

export function getPushOrganization(payload) {
  return {
    type: actionsTypes.GET_PUSH_ORGANIZATION,
    payload
  };
}
export function getPushOrganizationSuccess(payload) {
  return {
    type: actionsTypes.GET_PUSH_ORGANIZATION_SUCCESS,
    payload
  };
}
export function editPushOrganization(payload) {
  return {
    type: actionsTypes.EDIT_PUSH_ORGANIZATION,
    payload
  };
}
