import { connect } from 'react-redux';
import OrganizationManagement from './OrganizationManagement';
import {
  getListOrganizations,
  setSelectOrganization
} from '../../store/organization/actions';

const mapStateToProps = ({
  organizationReducer
}) => ({
  listData: organizationReducer.listData,
  selectOrganization: organizationReducer.selectOrganization
});
const mapDispatchToProps = {
  getListOrganizations,
  setSelectOrganization
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationManagement);
