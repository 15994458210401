import React, {
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';
import {
  Button,
  Form,
  Space,
  Select,
  DatePicker,
  InputNumber,
  Input,
  Typography,
  Divider,
  Modal,
  Row,
  Col,
  Tabs
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import QRCode from 'react-qr-code';
import moment from 'moment';
import style from './eventmanagement.module.scss';
import TabEvent from '../../components/TabEvent';
import { randomCode } from '../../utils/function';
import EventReportUpload from '../../components/EventReportUpload';

const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { TabPane } = Tabs;

const EventManagement = ({
  getListEventVotes,
  getDataEventVote,
  setSelectEvent,
  selectEvent,
  dataSelectEvent,
  listVotes,
  createEvent,
  deleteEvent,
  setDataEditEvent,
  editEvent
}) => {
  const [nameEvent, setNameEvent] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    getListEventVotes();
  }, [getListEventVotes]);

  useEffect(() => {
    if (dataSelectEvent.uuid) {
      form.setFieldsValue({
        uuid: dataSelectEvent.uuid,
        countLike: dataSelectEvent.countLike,
        date: [
          dataSelectEvent.beginAt && moment(dataSelectEvent.beginAt),
          dataSelectEvent.endAt && moment(dataSelectEvent.endAt)
        ]
      });
    }
  }, [
    dataSelectEvent.beginAt,
    dataSelectEvent.countLike,
    dataSelectEvent.endAt,
    dataSelectEvent.uuid,
    form
  ]);

  /**
   * Выбор селектора мероприятия
   * */
  const onChangeEvent = useCallback((value) => {
    setSelectEvent(value);
    getDataEventVote({
      voteUUID: value
    });
  }, [setSelectEvent, getDataEventVote]);

  /**
   * кнопка обновления QR
   * */
  const onUpdateQR = useCallback(() => {
    setDataEditEvent({
      inviteCode: randomCode()
    });
  }, [setDataEditEvent]);

  /**
   * Создание мероприятия
   * */
  const onCreateEvent = useCallback(() => {
    if (nameEvent && nameEvent.length >= 2) {
      createEvent({
        title: nameEvent,
        setNameEvent
      });
    }
  }, [createEvent, nameEvent]);

  /**
   * Удаление мероприятия
   * */
  const onDeleteEvent = useCallback(() => {
    confirm({
      title: 'Удалить мероприятие?',
      onOk() {
        deleteEvent({
          uuid: dataSelectEvent?.uuid
        });
      }
    });
  }, [dataSelectEvent, deleteEvent]);

  /**
   * Редактирование мероприятия
   * */
  const onSaveEditEvent = useCallback(() => {
    editEvent({
      ...dataSelectEvent
    });
  }, [dataSelectEvent, editEvent]);

  /**
   * Выбор перода
   * */
  const onRangePicker = useCallback((value) => {
    setDataEditEvent({
      beginAt: value && value[0] ? value[0].valueOf() : null,
      endAt: value && value[1] ? value[1].valueOf() : null
    });
  }, [setDataEditEvent]);

  /**
   * Печать куркода
   * */
  const onClickPrint = useCallback(() => {
    const myWindow = window.open('', 'mywindow', 'status=1,width=800,height=500');
    myWindow.document.write('<html><head><title>QR Code</title></head>');
    myWindow.document.write('<body onafterprint="self.close()">');
    myWindow.document.write(document.getElementById('qrPrint').innerHTML);
    myWindow.document.write('</body></html>');
    myWindow.print();
  }, []);

  return (
    <div className="dd-container">
      <div className={`dd-container-main ${style.containers}`}>
        <div className={style.header}>
          <h2>Управление мероприятиями</h2>
          <Form name="basic"
            layout="vertical"
            form={form}
            autoComplete="off"
            requiredMark={false}
          >
            <Space>
              <Form.Item label="Выберите мероприятие"
                name="uuid"
              >
                <Select placeholder="Выберите мероприятие"
                  style={{ width: 350 }}
                  fieldNames={{ label: 'title', value: 'uuid' }}
                  options={listVotes}
                  value={selectEvent}
                  onChange={onChangeEvent}
                  dropdownRender={(menu) => (
                    <>
                      <Space align="center" className={style.addEvent}>
                        <Input.Group style={{ width: '100%' }}>
                          <Input style={{ width: 'calc(100% - 32px)' }}
                            placeholder="Введите название мероприятия"
                            value={nameEvent}
                            onChange={(e) => setNameEvent(e?.target?.value)}
                          />
                          <Button type="primary"
                            icon={<PlusOutlined />}
                            onClick={onCreateEvent}
                          />
                        </Input.Group>
                      </Space>
                      <Divider style={{ margin: '2px 0 8px 0' }} />
                      {menu}
                    </>
                  )}
                />
              </Form.Item>
              <Form.Item label="Период голосования"
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'Введите период голосования'
                  }
                ]}
              >
                <RangePicker onChange={onRangePicker}
                  disabled={!selectEvent}
                  format="DD.MM.YYYY"
                />
              </Form.Item>
              <Form.Item label="Голоса"
                name="countLike"
                rules={[
                  {
                    required: true,
                    message: 'Введите количество голосов'
                  }
                ]}
              >
                <InputNumber onChange={(value) => setDataEditEvent({ countLike: value })}
                  disabled={!selectEvent}
                />
              </Form.Item>
            </Space>
          </Form>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Выгрузка отчета"
            key="1"
            disabled={!selectEvent}
          >
            <EventReportUpload />
          </TabPane>
          <TabPane tab="Данные мероприятия"
            key="3"
            disabled={!selectEvent}
          >
            <div className={style.main}>
              {dataSelectEvent?.title && <TabEvent />}
            </div>
            <div className={style.footer}>
              <Space>
                <div className="qrcode" id="qrPrint">
                  {dataSelectEvent?.inviteCode && <QRCode size={100} value={dataSelectEvent?.inviteCode} />}
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  {dataSelectEvent?.inviteCode && <div className="print" onClick={onClickPrint} />}
                </div>
                <Button disabled={!dataSelectEvent}
                  onClick={onUpdateQR}
                >
                  Обновить код
                </Button>
              </Space>
              <Space>
                <Button type="primary"
                  disabled={!dataSelectEvent?.isEdit}
                  onClick={onSaveEditEvent}
                >
                  Сохранить мероприятие
                </Button>
                <Button type="dashed"
                  disabled={!selectEvent}
                  onClick={onDeleteEvent}
                >
                  Удалить
                </Button>
              </Space>
            </div>
          </TabPane>
        </Tabs>

      </div>
    </div>
  );
};

export default EventManagement;
