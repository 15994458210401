import { connect } from 'react-redux';
import PersonnelManagement from './PersonnelManagement';
import {
  getListOrganizations
} from '../../store/organization/actions';
import {
  setSelectOrganizationOnPersonnel,
  openModalPersonnelOnOrganization,
  openModalCreatePerson,
  importPersonUploadCsvFile,
  acceptAllPersonsInOrganization
} from '../../store/personnel/actions';

const mapStateToProps = ({
  organizationReducer: {
    listData
  },
  personnelReducer: {
    selectOrganization
  },
  userReducer: {
    role
  }
}) => ({
  listData,
  selectOrganization,
  role
});
const mapDispatchToProps = {
  getListOrganizations,
  setSelectOrganizationOnPersonnel,
  openModalPersonnelOnOrganization,
  openModalCreatePerson,
  acceptAllPersonsInOrganization,
  importPersonUploadCsvFile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonnelManagement);
