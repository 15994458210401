import React, {
  useEffect,
  useCallback
} from 'react';
import {
  Select,
  Tabs,
  Row,
  Col
} from 'antd';
import ReportUploadAndQuickStatistics from '../../components/ReportUploadAndQuickStatistics';
import NotificationManagement from '../../components/NotificationManagement';
import FormCreatingOrEditOrganization from '../../components/FormCreatingOrEditOrganization';

const { TabPane } = Tabs;

const OrganizationManagement = ({
  listData,
  getListOrganizations,
  setSelectOrganization,
  selectOrganization
}) => {
  useEffect(() => {
    getListOrganizations();
  }, [getListOrganizations]);

  const onChangeOrganize = useCallback((value) => {
    setSelectOrganization(value);
  }, [setSelectOrganization]);

  return (
    <div className="dd-container">
      <div className="dd-container-main">
        <h2>Управление организацией</h2>
        <Row gutter={[40, 8]}>
          <Col md={24} lg={12}>
            <Select style={{ width: '100%' }}
                    placeholder="Выберите организацию"
                    fieldNames={{ label: 'title', value: 'uuid' }}
                    options={listData}
                    onChange={onChangeOrganize}
                    value={selectOrganization}
            />
          </Col>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Выгрузка отчета / Быстрая статистика"
                   key="1"
                   disabled={!selectOrganization}
          >
            <ReportUploadAndQuickStatistics />
          </TabPane>
          <TabPane tab="Управление уведомлениями"
                   key="2"
                   disabled={!selectOrganization}
          >
            <NotificationManagement />
          </TabPane>
          <TabPane tab="Данные организации"
                   key="3"
                   disabled={!selectOrganization}
          >
            <h2>Данные организации</h2>
            <FormCreatingOrEditOrganization isEdit />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default OrganizationManagement;
