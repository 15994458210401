import React, {
  useState,
  useCallback
} from 'react';
import { Modal, Button, Input } from 'antd';

const DeleteOrganization = ({
  selectOrganization,
  deleteInfoOrganization
}) => {
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState('');
  const onOk = useCallback(() => {
    setVisible(false);
    deleteInfoOrganization({
      password,
      uuid: selectOrganization
    });
  }, [deleteInfoOrganization, password, selectOrganization]);

  return (
    <>
      <Button type="link"
              onClick={() => setVisible(true)}
              disabled={!selectOrganization}
      >
        Удалить
      </Button>
      <Modal
        title="Удалить организацию ?"
        visible={visible}
        onOk={onOk}
        onCancel={() => setVisible(false)}
        okText="Удалить"
        cancelText="Отмена"
      >
        <p>Для удаления организации введите пароль</p>
        <Input.Password value={password}
                        onChange={(value) => setPassword(value)}
        />
      </Modal>
    </>
  );
};

export default DeleteOrganization;
