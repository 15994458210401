import {
  all,
  call,
  put,
  takeEvery,
  select
} from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './types';
import {
  get,
  post,
  put as axiosPut,
  apiDelete
} from '../../utils/api';
import { popupLoaderClose, popupLoaderOpen } from '../loader/actions';
import { errorModal, openNotificationWithIcon } from '../../utils/function';
import {
  ADMINS, ROLES, ADMIN
} from '../../utils/url';

function* getListAdmins({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      `${ADMINS}?offset=${payload.offset}&limit=${payload.limit}`
    );
    yield put(actions.getListAdminsSuccess({
      ...response,
      limit: payload.limit
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* updateListAdmins() {
  const { admins } = yield select(({ rolesReducer }) => rolesReducer);
  yield put(actions.getListAdmins({
    offset: ((admins.pagination.current ? admins.pagination.current : 1) - 1) * admins.pagination.pageSize,
    limit: admins.pagination.pageSize
  }));
}

function* getListRoles({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      ROLES
    );
    yield put(actions.getListRolesSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/**

  {
    * "lastName": "Катамаранов",
    * "firstName": "Игорь",
    "middleName": "Натальевич",
    * "login": "79998887766",
    * "email": "inkatamaranov@qm.ru",
    "organization": {
      "uuid": "31cd402e-bdaf-47a1-b129-3077230bf8fd"
    },
    * "role": {
      "code": "ADMIN"
    }
  }

 * */
function* createAdmin({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      post,
      ADMIN,
      {
        lastName: payload.data.lastName,
        firstName: payload.data.firstName,
        middleName: payload.data.middleName ? payload.data.middleName : null,
        login: payload.data.login,
        email: payload.data.email,
        organization: payload.data.uuid ? { uuid: payload.data.uuid } : null,
        role: { code: payload.data.code }
      }
    );
    yield all([updateListAdmins()]);
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Пользователь успешно создан',
      null
    );
    if (payload.form) {
      payload.form.resetFields();
    }
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* editAdmin({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      `${ADMIN}/${payload.data.uuid}`,
      {
        lastName: payload.data.lastName,
        firstName: payload.data.firstName,
        middleName: payload.data.middleName ? payload.data.middleName : null,
        login: payload.data.login,
        email: payload.data.email,
        organization: payload.data.uuidOrganize ? { uuid: payload.data.uuidOrganize } : null,
        role: { code: payload.data.code }
      }
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Данные о пользователе обновлены',
      null
    );
    yield all([updateListAdmins()]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* deleteAdmin({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      apiDelete,
      `${ADMIN}/${payload.uuid}`
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Пользователь удален',
      null
    );
    yield all([updateListAdmins()]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* resetPassword({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      get,
      `${ADMIN}/${payload.uuid}/resetPassword`
    );
    payload.setIsPress(true);
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

// eslint-disable-next-line
export default function* () {
  yield all([
    takeEvery(actionTypes.GET_LIST_ADMINS, getListAdmins),
    takeEvery(actionTypes.GET_LIST_ROLES, getListRoles),
    takeEvery(actionTypes.CREATE_ADMIN, createAdmin),
    takeEvery(actionTypes.EDIT_ADMIN, editAdmin),
    takeEvery(actionTypes.DELETE_ADMIN, deleteAdmin),
    takeEvery(actionTypes.RESET_PASSWORD, resetPassword)
  ]);
}
