import {
  all,
  call,
  put,
  takeEvery,
  delay
} from 'redux-saga/effects';
// import { saveAs } from 'file-saver';
import moment from 'moment';
import * as actions from './actions';
import * as actionTypes from './types';
import {
  get,
  post,
  put as axiosPut,
  apiDelete,
  getFile
} from '../../utils/api';
import {
  ORGANIZATION,
  ORGANIZATION_ON_UUID,
  ORGANIZATION_ON_UUID_STATISTICS,
  ORGANIZATIONS,
  ORGANIZATION_REPORTS,
  ORGANIZATION_REPORT,
  ORGANIZATION_PUSH
} from '../../utils/url';
import { popupLoaderClose, popupLoaderOpen } from '../loader/actions';
import { errorModal, openNotificationWithIcon } from '../../utils/function';

/**
 * {
 *   "title": "ООО \"Тестирование\"",
 *   "email": "organization@test.ru",
 *   "inn": "7707083893",
 *   "kpp": "773601001",
 *   "ogrn": "1027700132195",
 *   "bankAccount": "40702810300020038047",
 *   "bank": "ПАО \"СБЕРБАНК РОССИИ\"",
 *   "inviteCode": "GR12K13Y"
 * }
 * */
function* createOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      post,
      ORGANIZATION,
      {
        title: payload.data.title,
        email: payload.data.email ? payload.data.email : null,
        inn: payload.data.inn ? payload.data.inn : null,
        kpp: payload.data.kpp ? payload.data.kpp : null,
        ogrn: payload.data.ogrn ? payload.data.ogrn : null,
        bankAccount: payload.data.bankAccount ? payload.data.bankAccount : null,
        bank: payload.data.bank ? payload.data.bank : null,
        inviteCode: payload.data.inviteCode ? payload.data.inviteCode : null
      }
    );
    // todo сброс формы при успешном сохранении * payload.form *
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Организация успешно создана',
      null
    );
    if (payload.form) {
      payload.form.resetFields();
    }
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getListOrganizations({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      ORGANIZATIONS
    );
    yield put(actions.getListOrganizationsSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getInfoOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      ORGANIZATION_ON_UUID(payload)
    );
    yield put(actions.getInfoOrganizationSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* editInfoOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      ORGANIZATION_ON_UUID(payload.uuid),
      {
        title: payload.data.title,
        email: payload.data.email ? payload.data.email : null,
        inn: payload.data.inn ? payload.data.inn : null,
        kpp: payload.data.kpp ? payload.data.kpp : null,
        ogrn: payload.data.ogrn ? payload.data.ogrn : null,
        bankAccount: payload.data.bankAccount ? payload.data.bankAccount : null,
        bank: payload.data.bank ? payload.data.bank : null,
        inviteCode: payload.data.inviteCode ? payload.data.inviteCode : null
      }
    );
    // todo сброс формы при успешном сохранении * payload.form *
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Данные организации изменены',
      null
    );
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* deleteInfoOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      apiDelete,
      ORGANIZATION_ON_UUID(payload.uuid),
      {
        password: payload.password
      }
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Организация успешно удалена',
      null
    );
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getStaticOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      ORGANIZATION_ON_UUID_STATISTICS(payload)
    );
    yield put(actions.getStaticOrganizationSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getListOrganizationReports({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      ORGANIZATION_REPORTS(payload)
    );
    yield put(actions.getListOrganizationReportsSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getCreateOrganizationReport({ payload }) {
  try {
    yield put(popupLoaderOpen());

    // eslint-disable-next-line no-underscore-dangle
    let beginAt = moment(payload.beginAt._d.getTime());
    beginAt.hour(0);
    beginAt.minute(0);
    beginAt.second(0);
    beginAt.millisecond(0);
    beginAt = beginAt.valueOf();

    let endAt = null;
    // eslint-disable-next-line no-underscore-dangle
    if (payload.endAt._d.getTime()) {
      endAt = moment(payload.endAt);
      endAt.hour(23);
      endAt.minute(59);
      endAt.second(59);
      endAt.millisecond(999);
      endAt = endAt.valueOf();
    }

    const response = yield call(
      post,
      ORGANIZATION_REPORT(payload.uuid, payload.code),
      {
        beginAt,
        endAt
      }
    );
    let fileName;
    let fileBlob;
    let temp = true;
    let i = 0;
    while (temp && i < 30) {
      yield delay(response.retryAfter * 1000);
      try {
        [fileName, fileBlob] = yield call(getFile, response.location);
        temp = false;
      } catch (e) {
        if (e?.response?.status !== 425) {
          temp = false;
          yield put(popupLoaderClose());
          errorModal(
            e?.response?.data?.error,
            e?.response?.data?.message,
            e?.response?.data?.data
          );
        }
      }
      i += 1;
    }
    // const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    // eslint-disable-next-line no-debugger
    // debugger;

    // saveAs(file);
    // window.open(URL.createObjectURL(blob));

    // ---
    const fileLink = document.createElement('a');
    fileLink.download = fileName;
    fileLink.href = URL.createObjectURL(fileBlob);
    fileLink.click();
    // ---
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getPushOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      `${ORGANIZATION_PUSH}/${payload.uuid}`
    );
    yield put(actions.getPushOrganizationSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* editPushOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      `${ORGANIZATION_PUSH}/${payload.uuid}`,
      {
        ...payload.data
      }
    );
    yield put(actions.getPushOrganization({ uuid: payload.uuid }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

// eslint-disable-next-line
export default function* () {
  yield all([
    takeEvery(actionTypes.CREATE_ORGANIZATION, createOrganization),
    takeEvery(actionTypes.GET_LIST_ORGANIZATIONS, getListOrganizations),
    takeEvery(actionTypes.GET_INFO_ORGANIZATION, getInfoOrganization),
    takeEvery(actionTypes.EDIT_INFO_ORGANIZATION, editInfoOrganization),
    takeEvery(actionTypes.DELETE_INFO_ORGANIZATION, deleteInfoOrganization),
    takeEvery(actionTypes.GET_STATIC_ORGANIZATION, getStaticOrganization),
    takeEvery(actionTypes.GET_LIST_ORGANIZATION_REPORTS, getListOrganizationReports),
    takeEvery(actionTypes.GET_CREATE_ORGANIZATION_REPORT, getCreateOrganizationReport),
    takeEvery(actionTypes.GET_PUSH_ORGANIZATION, getPushOrganization),
    takeEvery(actionTypes.EDIT_PUSH_ORGANIZATION, editPushOrganization)
  ]);
}
