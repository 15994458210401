import React, {
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Modal,
  Typography
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import moment from 'moment';
import style from './formcreatingoreditperson.module.scss';

const { Option } = Select;
const { confirm } = Modal;

// {
//   * "lastName": "Катамаранов",
//   * "firstName": "Игорь",
//   "middleName": "Натальевич",
//   "gender": "Male",
//   "birthDate": 1633355410406,
//   * "personalPhone": "79998887766",
//   * "personalEmail": "inkatamaranov@qm.ru",
//   "personalId": null,
//   "employee": {
//     "workPhone": "8 (800) 453-33-22, доб. 112",
//     "position": "Инженер по качеству",
//     "department": "Отдел управления качеством",
//     "departmentTwo": null,
//     "departmentThree": null,
//     "status": "Сотрудник",
//     "speciality": "Мерник",
//     "employmentDate": 1633355410406,
//     "achievements": "Измерил неизмеримое",
//     "merits": "Сломал измеритель и не только..."
// }
// }

const FormCreatingOrEditPerson = ({
  createPersonnelOnOrganization,
  editPersonnelOnOrganization,
  deletePersonnelOnOrganization,
  selectOrganization,
  closeModalCreatePerson,
  isModal,
  isTable,
  record,
  isModalVisibleCreatePerson,
  setKeyRowExpanded
}) => {
  const [tempPhone, setTempPhone] = useState(null);
  const [acceptedVidible, setAccceptedVisible] = useState(!record?.employee?.dismissalDate);
  const [acceptedChecked, setAcceptedChecked] = useState(record?.employee?.acceptedAt);
  const [form] = Form.useForm();

  useEffect(() => {
    if (record?.firstName && form) {
      form.setFieldsValue({
        ...record.employee,
        lastName: record.lastName,
        firstName: record.firstName,
        middleName: record.middleName,
        gender: record.gender,
        birthDate: record.birthDate && moment(record.birthDate),
        employmentDate: record?.employee?.employmentDate && moment(record?.employee?.employmentDate),
        dismissalDate: record?.employee?.dismissalDate && moment(record?.employee?.dismissalDate),
        personalPhone: record.personalPhone,
        personalEmail: record.personalEmail,
        personalId: record.personalId,
        acceptedAt: record?.employee?.acceptedAt
      });
      setTempPhone(record.personalPhone);
    }
  }, [isModal, record, form]);

  useEffect(() => {
    if (!isModalVisibleCreatePerson && !record?.firstName) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, isModalVisibleCreatePerson, record]);

  const deleteModal = useCallback(() => {
    confirm({
      title: 'Удалить пользователя?',
      icon: <ExclamationCircleOutlined />,
      content: `${record?.lastName} ${record?.firstName} ${record?.middleName}`,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        deletePersonnelOnOrganization({
          userUuid: record?.uuid,
          organizationUuid: selectOrganization
        });
      }
    });
  }, [
    deletePersonnelOnOrganization,
    record,
    selectOrganization
  ]);

  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        if (isModal) {
          createPersonnelOnOrganization({
            ...values,
            birthDate: values.birthDate && values.birthDate.valueOf(),
            employmentDate: values.employmentDate && values.employmentDate.valueOf(),
            dismissalDate: values.dismissalDate && values.dismissalDate.valueOf(),
            acceptedAt: !values.acceptedAt ? null : record?.employee?.acceptedAt ?? new Date().getTime(),
            organizationUuid: selectOrganization,
            personalPhone: values.personalPhone.replace(/[+()-]/g, '')
          });
        } else {
          editPersonnelOnOrganization({
            ...values,
            userUuid: record?.uuid,
            acceptedAt: !values.acceptedAt ? null : record?.employee?.acceptedAt ?? new Date().getTime(),
            birthDate: values.birthDate && values.birthDate.valueOf(),
            employmentDate: values.employmentDate && values.employmentDate.valueOf(),
            dismissalDate: values.dismissalDate && values.dismissalDate.valueOf(),
            organizationUuid: selectOrganization,
            personalPhone: values.personalPhone.replace(/[+()-]/g, ''),
            setKeyRowExpanded
          });
        }
      })
      .catch((errorInfo) => {

      });
  }, [
    isModal,
    form,
    record,
    createPersonnelOnOrganization,
    editPersonnelOnOrganization,
    selectOrganization,
    setKeyRowExpanded
  ]);

  const closeModal = useCallback(() => {
    if (isModal) {
      closeModalCreatePerson();
    } else {
      setKeyRowExpanded((values) => values.filter((item) => item !== record?.uuid));
    }
  }, [closeModalCreatePerson, isModal, setKeyRowExpanded, record]);

  const renderPhone = useMemo(() => (
    <MaskedInput mask="+7(000)000-00-00"
      value={String(tempPhone)}
      onChange={(value) => setTempPhone(value)}
    />
  ), [tempPhone]);

  return (
    <Form name="basic"
      layout="vertical"
      form={form}
      autoComplete="off"
      requiredMark={false}
    >
      <Row gutter={[8, 8]}>
        <Col md={24} lg={12}>
          <Row gutter={[8, 8]}>
            <Col md={12}>
              <Form.Item label="Фамилия"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Введите фамилию'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Табельный"
                name="personalId"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col md={12}>
              <Form.Item label="Имя"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Введите имя'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Отдел 1"
                name="department"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col md={12}>
              <Form.Item label="Отчество"
                name="middleName"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Отдел 2"
                name="departmentTwo"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col md={12}>
              <Form.Item label="Должность"
                name="position"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Отдел 3"
                name="departmentThree"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col md={12}>
              <Form.Item label="Статус"
                name="status"
              >
                <Select style={{ width: '100%' }} >
                  <Option value="Сотрудник">Сотрудник</Option>
                  <Option value="HR">HR</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Специальность"
                name="speciality"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={24} lg={12}>
          <Row gutter={[8, 8]}>
            <Col md={24} lg={12}>
              <Row gutter={[8, 8]}>
                <Col md={24} >
                  <Form.Item label="Почта"
                    name="personalEmail"
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: 'Введите email'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col md={15}>
                  <Form.Item label="Тел.мобильный"
                    name="personalPhone"
                    rules={[
                      {
                        // required: true,
                        // pattern: new RegExp(/\\d{11}/g),
                        // message: 'Введите мобильный телефон',
                        // transform: (value) => value && value.replace(/[_|(|)|+|-]/g, ''),
                        validator: (_, tValue) => {
                          const temp = tValue && tValue.match(/\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/g);
                          const tempDop = tValue && tValue.match(/\d{11}/g);
                          return (
                            (temp && temp.length) || (tempDop && tempDop.length)
                              ? Promise.resolve()
                              : Promise.reject(new Error('Введите мобильный телефон'))
                          );
                        }
                      }
                    ]}
                  >
                    {renderPhone}
                  </Form.Item>
                </Col>
                <Col md={9}>
                  <Form.Item label="Тел.рабочий"
                    name="workPhone"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col md={15}>
                  <Form.Item label="Дата рождения"
                    name="birthDate"
                  >
                    <DatePicker style={{ width: '100%' }}
                      format="DD.MM.YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col md={9}>
                  <Form.Item label="Пол"
                    name="gender"
                  >
                    <Select style={{ width: '100%' }} >
                      <Option value="Male">Мужской</Option>
                      <Option value="Female">Женский</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col md={15}>
                  <Form.Item label="Дата зачисления"
                    name="employmentDate"
                  >
                    <DatePicker style={{ width: '100%' }}
                      format="DD.MM.YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col md={15}>
                  <Form.Item label="Дата увольнения"
                    name="dismissalDate" >
                    <DatePicker style={{ width: '100%' }}
                      format="DD.MM.YYYY" onChange={() => setAccceptedVisible(false)}
                    />
                  </Form.Item>
                </Col>
                <Col md={9}>
                  <Form.Item>
                    <Button type="field"
                      block
                      onClick={() => {
                        form.resetFields(['dismissalDate']);
                        setAccceptedVisible(true);
                      }}
                    >
                      Очистить
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col md={24} lg={12}>
              <Row gutter={[8, 8]}>
                <Col md={24}>
                  <Form.Item label="Достижения"
                    name="achievements"
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col md={24}>
                  <Form.Item label="Заслуги"
                    name="merits"
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col md={24}>
                  <Form.Item wrapperCol={{ span: 1, offset: 1 }}
                    name="acceptedAt" label=" " valuePropName="checked" hidden={!acceptedVidible}
                  >
                    <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                      <Typography style={{ marginRight: 10 }}>Сотрудник акцептован</Typography>
                      <Checkbox checked={acceptedChecked}
                        onChange={(event) => setAcceptedChecked(event.target.checked)} />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 8]} justify="space-between" align="bottom" className={style.button}>
            <Col md={24} lg={12}>
              {!isModal && (
                <Form.Item>
                  <Button block
                    onClick={deleteModal}
                  >
                    Удалить пользователя
                  </Button>
                </Form.Item>
              )}
            </Col>
            <Col md={24} lg={12}>
              <Row gutter={[8, 8]}>
                <Col md={12}>
                  <Form.Item>
                    <Button block
                      onClick={closeModal}
                    >
                      Отмена
                    </Button>
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item>
                    <Button type="primary"
                      htmlType="submit"
                      onClick={handleFormSubmit}
                      block
                    >
                      Сохранить
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(FormCreatingOrEditPerson);
